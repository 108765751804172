











































































@import '@design';
@import '@styleMixins';

.other-mention.hover {
  .other-mentions-link {
    color: $color-bubs-red !important;
    &::after {
      width: 100%;
      // height: 6px;
    }
  }
  .press-title a {
    // color: $color-bubs-red;
  }
}

.press-title {
  a {
    color: $color-bubs-charcoal;
    text-decoration: none;
    transition: color 0.4s ease-in-out;

    @include font-size(20px, 0.8, 0.8, 0.85);
    line-height: 1.3 !important;
  }
}

.other-mentions-link {
  position: relative;
  padding-bottom: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s ease-in-out;

  @include font-size(30px, 0.8, 0.8, 0.85);

  // stylelint-disable-next-line
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    content: '';
    background: $color-bubs-red;
    transition: width 0.4s ease-in-out, height 0.2s ease-in-out;
  }

  &.small::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
